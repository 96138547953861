/* eslint-disable*/
import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle, Button,
} from "reactstrap"
import * as Icon from "react-feather"
import { history } from "../../../history"
import * as constant from "../../../configs/constant"
import * as actionCreator from "../../../store/domain/auth/action";
import {
  connect,
} from 'react-redux';
import * as commonFunc from "../../../utility/commonFunc";
import CorporateImage from "../../../assets/img/views/profile/pro-pic.png";
import swal from "sweetalert";
import Cookies from "js-cookie";
import {ROUTE_POST_AD, USER_LANGUAGE} from "../../../configs/constant";
import { Translate } from '@google-cloud/translate';
import {Dropdown} from "semantic-ui-react";
import * as config from "../../../configs/constant";
import $ from 'jquery';

const signoutHandler = (props) => {
  swal({
    title: commonFunc.sureTxt,
    // icon: warningIcon,
    closeOnClickOutside: false,
    buttons: {
      cancel: 'No',
      dangerMode: {
        text: "Yes",
        value: "action",
        className: "okay-btn"
      }
    }
  })
    .then((value) => {
      switch (value) {
        case "action":
          // props.logoutUser();
          Cookies.remove(constant.ACCESS_TOKEN);
          history.push(constant.BASE_ROUTE_PATH+"/login")
          break;
        default:
      }
    })
};

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      {/*<DropdownItem*/}
        {/*tag="a"*/}
        {/*href="#"*/}
        {/*onClick={e => handleNavigation(e, constant.BASE_ROUTE_PATH+'/profile')}*/}
      {/*>*/}
        {/*<Icon.User size={14} className="mr-50" />*/}
        {/*<span className="align-middle">Profile</span>*/}
      {/*</DropdownItem>*/}

      {/*<DropdownItem divider />*/}

      <DropdownItem
        onClick={()=>signoutHandler(props)}
      >
        <span className="align-middle">Log Out</span>
      </DropdownItem>
      <DropdownItem
        onClick={()=>signoutHandler(props)}
      >
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
};
let stateVal = null;
class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false, lang: 'en'
  };
   googleTranslateElementInit = () => {
     console.log("[lang] init...")
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: 'en,si,ta',
        // autoDisplay: false
      },
      "google_translate_element"
    );
     let language = localStorage.getItem(USER_LANGUAGE);
     if(language) this.changeLanguage(language)
  };
   componentDidMount() {

     let addScript = document.createElement("script");
     addScript.setAttribute(
       "src",
       "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
     );
     document.body.appendChild(addScript);
     window.googleTranslateElementInit = this.googleTranslateElementInit;
   }
  triggerEvent = (element, eventName) => {
     console.log("triggerEvent")
    const event = new Event(eventName);
    element.dispatchEvent(event);
    if(stateVal !== null) window.location = window.location.href;
    stateVal = 123;
  };

  changeLanguage = (value) => {
    console.log("changeLanguage")
    this.setState({lang: value})
    $('.goog-te-combo').val(value);
    let element = document.querySelector('.goog-te-combo');
    if(element) this.triggerEvent(element, 'change');

  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.status !== -1){
      // commonFunc.notifyMessage(nextProps.message,nextProps.status)
      this.props.resetHandler();
    }
    if(!nextProps.loggedInStatus){
      history.push(constant.BASE_ROUTE_PATH+"/login")
    }
  }
  dropdownHandler = (status) => (e, {value}) => {
    localStorage.setItem(USER_LANGUAGE,value)
    this.changeLanguage(value);
  }
  // d-none
  render() {
     let {lang} = this.state;
    let token = Cookies.get(config.ACCESS_TOKEN);
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        {/*<div id="google_translate_element" />*/}
        {/*<div>*/}
        {/*  <UncontrolledDropdown tag="li" className="dropdown-user nav-item">*/}
        {/*    <DropdownToggle tag="a" className="nav-link dropdown-user-link">*/}
        {/*      <div className="user-nav d-sm-flex ">*/}
        {/*        <span className="user-name text-bold-600">Admin</span>*/}
        {/*      </div>*/}
        {/*    </DropdownToggle>*/}
        {/*    <UserDropdown {...this.props} />*/}
        {/*  </UncontrolledDropdown>*/}
        {/*</div>*/}
        <div className={'lang-wrapper notranslate'}>
          <Dropdown placeholder='' fluid search={false} selection options={[{text:"English", value:"en"},{text:"Sinhala", value:"si"},{text:"Tamil", value:"ta"}]} value={lang}
                    onChange={this.dropdownHandler('lang')} selectOnBlur={false} clearable={false}/>
          {/*<div id="google_translate_element" >ABC</div>*/}
        </div>
        {token ? <div className={'post-ad-btn notranslate'}>
          <Button.Ripple color="white" type="submit" onClick={()=>history.push(constant.BASE_ROUTE_PATH+constant.ROUTE_POST_AD)}>
            POST YOUR AD
          </Button.Ripple>
        </div>: null}

        {/*<UncontrolledDropdown tag="li" className="dropdown-user nav-item">*/}
        {/*  <DropdownToggle tag="a" className="nav-link dropdown-user-link">*/}
        {/*    /!*<div className="user-nav d-sm-flex ">*!/*/}
        {/*      /!*<span className="user-name text-bold-600">Admin</span>*!/*/}
        {/*    /!*</div>*!/*/}
        {/*    <span data-tour="user">*/}
        {/*      <img*/}
        {/*        src={CorporateImage}*/}
        {/*        className="half-round nav-logo"*/}
        {/*        height="40"*/}
        {/*        width="40"*/}
        {/*        alt="avatar"*/}
        {/*      />*/}
        {/*    </span>*/}
        {/*  </DropdownToggle>*/}
        {/*  <UserDropdown {...this.props} />*/}
        {/*</UncontrolledDropdown>*/}
      </ul>
    )
  }
}
const mapStateToProps = (state) => ({
  loggedInStatus: state.auth.loggedInStatus,
  status: state.auth.status,
  message: state.auth.message
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: userCredentials => dispatch(actionCreator.logoutUser(userCredentials)),
  resetHandler: () => dispatch(actionCreator.resetWarningHandler())
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);
